@import '~app/mixins';

.header {
  font-size: 16px;
  line-height: 18px;
  padding-top: 10px;
  color: $color-blue-dark;
}

.body,
.footer {
  button {
    margin: 0 6px;
    font-weight: 400;
  }
}

.footer button,
.buttonCell {
  line-height: 1;
}

.footer {
  display: flex;
  justify-content: space-between;
}

.body {
  margin: 5px 0;
  overflow-x: hidden;
  overflow-y: hidden;
  height: calc(100vh - 300px);

  .inputContainer label {
    display: none;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .cellInput {
    input {
      margin-top: 6px;
    }
  }

  .hiddenCell {
    opacity: 0;
  }

  table tr:hover .hiddenCell {
    opacity: 1;
  }

  .thRow {
    background: #e3e5e8;
    padding: 5px;
    height: 36px;
    margin: 13px -6px 0;
  }

  .remaining {
    color: $color-grey-light;
    font-size: 12px;
    font-style: italic;
  }

  .recipientContainer {
    height: 100%;

    .recipientRow {
      height: 48%;
      max-height: 48%;
      box-shadow: 2px 2px 5px grey;
      width: 99.5%;

      &:last-child {
        margin-top: 15px;
      }

      .tableContainer {
        max-height: 90%;
        overflow: scroll;
      }

      &.full {
        height: 96%;
        max-height: 96%;
      }
    }

    .assignmentContainer {
      display: flex;
      margin: 5px;

      .blueLink {
        margin: 0;
        padding: 0;
      }

      label {
        color: $color-grey;
        font-size: 13px;
        padding-top: 5px;
        margin-right: 6px;
      }

      .title {
        color: $color-blue-dark;
        padding: 6px 0;
      }

      .dropDown {
        width: auto;
        min-width: 300px;
        margin-right: 30px;
      }
    }
  }
}

